import React, { useState, useEffect } from "react";
import {
  LaserEyesClient,
  createStores,
  createConfig,
} from "@omnisat/lasereyes-core";
import { toast, ToastContainer } from "react-toastify";
import { Buffer } from "buffer"; // Add Buffer import
import "react-toastify/dist/ReactToastify.css";
import "./App.css"; // Optional: Add custom CSS for enhanced styling

window.Buffer = Buffer; // Ensure Buffer is available globally

const SUPPORTED_MIME_TYPES = [
  "text/html",
  "text/css",
  "text/plain",
  "text/javascript",
  "application/json",
  "application/javascript",
  "application/xml",
  "application/pdf",
  "application/zip",
  "application/gzip",
  "application/xhtml+xml",
  "application/rss+xml",
  "application/atom+xml",
  "application/octet-stream",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/svg+xml",
  "image/webp",
  "image/x-icon",
  "video/mp4",
  "video/webm",
  "video/ogg",
  "audio/mpeg",
  "audio/wav",
  "audio/ogg",
];

const getNetworkFromURL = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("network") || "mainnet"; // Default to "mainnet" if not provided
};

const stores = createStores();
const initialNetwork = getNetworkFromURL();
const config = createConfig({ network: initialNetwork });
const client = new LaserEyesClient(stores, config);

const SUPPORTED_WALLETS = [
  "unisat",
  "xverse",
  "leather",
  "phantom",
  "okx",
  "wizz",
  "oyl",
  "orange",
]; // Extend as needed

const InscriptionApp = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(SUPPORTED_WALLETS[0]);
  const [txId, setTxId] = useState([]); // Initialize as an empty array
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [currentNetwork, setCurrentNetwork] = useState(initialNetwork);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling modal visibility
  const [selectedFiles, setSelectedFiles] = useState([]); // State for bulk file uploads

  const initializeClient = async () => {
    const maxRetries = 5;
    const retryDelay = 1000;

    for (let i = 0; i < maxRetries; i++) {
      if (window.unisat) {
        try {
          await client.checkInitializationComplete();
          // console.log("LE initialized successfully.");
          return;
        } catch (error) {
          console.error("Initialization error:", error);
          toast.error(error.message || "Failed to initialize LE.");
          return;
        }
      }
      // console.log(`UniSat not ready. Retrying (${i + 1}/${maxRetries})...`);
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
    }

    toast.error(
      "UniSat Wallet is not detected. Please ensure it is installed and available."
    );
  };

  useEffect(() => {
    let isMounted = true;

    const initializeClientWithPolling = async () => {
      const maxRetries = 10; // Number of retries
      const retryDelay = 1000; // Delay in milliseconds between retries

      for (let i = 0; i < maxRetries; i++) {
        if (window.unisat) {
          try {
            await client.checkInitializationComplete();
            // console.log("LEClient initialized successfully.");
            return;
          } catch (error) {
            handleError(error, "Failed to initialize LEClient.");
            return;
          }
        }

        // console.log(`UniSat not ready. Retrying (${i + 1}/${maxRetries})...`);
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }

      handleError(
        null,
        "UniSat Wallet is not ready. Please ensure it is installed and reload the page."
      );
    };

    initializeClientWithPolling();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    initializeClient();
  }, [currentNetwork]);

  const handleError = (error, defaultMessage) => {
    // Log the full error to the console for debugging
    console.error("Error:", error);

    // Extract the error message or use the default one
    const errorMessage =
      error?.message || defaultMessage || "An unexpected error occurred.";

    // Display the error message using a toast notification
    toast.error(errorMessage);
  };

  const disconnectWallet = () => {
    client.disconnect(); // Disconnect the client
    setWalletAddress(null); // Reset the wallet address to null
    setSelectedFile(null); // Clear the selected file
    setSelectedFileType(""); // Clear the selected file type
    setTxId(null); // Reset the transaction ID
    setIsModalOpen(false); // Ensure the modal is closed
    toast.info("Wallet disconnected successfully."); // Optional: Notify the user
  };

  const handleConnectWallet = async (wallet) => {
    setSelectedWallet(wallet);
    try {
      await client.connect(wallet);
      const accounts = await client.requestAccounts();
      setWalletAddress(accounts[0]);
      toast.success(`Connected to ${wallet} wallet!`);
      setIsModalOpen(false); // Close modal on successful connection
    } catch (error) {
      console.error("Wallet connection failed:", error);
      toast.error(`Failed to connect to ${wallet}: ${error.message}`);
    }
  };

  const handleWalletSelection = (event) => {
    setSelectedWallet(event.target.value);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) =>
      SUPPORTED_MIME_TYPES.includes(file.type)
    );

    if (validFiles.length > 0) {
      setSelectedFiles(validFiles);
      toast.info(`${validFiles.length} file(s) selected.`);
    } else {
      toast.error("No valid files selected. Please try again.");
    }
  };

  const handleInscribeFiles = async () => {
    if (!walletAddress) {
      handleError(null, "Please connect your wallet first.");
      return;
    }
  
    if (selectedFiles.length === 0) {
      handleError(null, "Please select files to inscribe.");
      return;
    }
  
    try {
      setIsLoading(true);
      const txIdDetails = [];
  
      for (const file of selectedFiles) {
        const reader = new FileReader();
        const promise = new Promise((resolve, reject) => {
          reader.onload = async (e) => {
            const fileContent = e.target.result;
            const contentBase64 = Buffer.from(fileContent, "binary").toString(
              "base64"
            );
  
            try {
              const txId = await client.inscribe(contentBase64, file.type);
              txIdDetails.push({ fileName: file.name, txId });
              toast.success(
                `File ${file.name} inscribed successfully! TX ID: ${txId}`
              );
              resolve(txId);
            } catch (error) {
              handleError(error, `Failed to inscribe file: ${file.name}`);
              reject(error);
            }
          };
        });
  
        reader.readAsBinaryString(file);
        await promise; // Wait for the current file to finish before starting the next
      }
  
      setTxId(txIdDetails); // Store array of { fileName, txId } objects
      toast.success(`All files inscribed successfully!`);
    } catch (error) {
      handleError(error, "An error occurred while inscribing files.");
    } finally {
      setIsLoading(false);
    }
  };
  

  // fee
  const fetchFeeRate = async () => {
    try {
      const response = await fetch("https://mempool.space/api/v1/fees/recommended");
      const data = await response.json();
      return data.fastestFee; // satoshis per byte (fastest confirmation)
    } catch (error) {
      console.error("Error fetching fee rate:", error);
      toast.error("Failed to fetch network fee rate. Please try again.");
      return null;
    }
  };

  
  const calculateEstimatedFee = async () => {
    if (selectedFiles.length === 0) {
      toast.error("Please select files to estimate the fee.");
      return;
    }
  
    const feeRate = await fetchFeeRate(); // Satoshis per byte
    if (!feeRate) return;
  
    const estimatedFees = selectedFiles.map((file) => {
      const estimatedTxSize = file.size + 50; // Approximate base transaction size
      return estimatedTxSize * feeRate; // Fee in satoshis for this file
    });
  
    const totalFee = estimatedFees.reduce((sum, fee) => sum + fee, 0); // Total fee
    toast.info(`Estimated Total Fee: ${totalFee} satoshis`);
    return totalFee;
  };

  


  return (
    <div className="min-h-screen bg-[#0F1011] text-white">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          background: "transparent",
          padding: "0",
          borderRadius: "0",
        }}
        className="custom-toast"
      />

      {/* Navbar */}
      <nav className="flex-col md:flex-row w-full bg-[#1C1E21] py-4 px-6 flex justify-between items-center border-b border-[#F7931A]/30">
        {/* Brand Logo */}
        <h1 className="text-2xl font-bold text-[#F7931A] mb-0">
          orDOS.io <span className="text-white">Inscription Portal</span>
        </h1>

        <div className="flex">
          {/* Network Display */}
          <div className="bg-[#1C1E21] p-4">
            <h2 className="text-lg  text-[#F7931A]">
              Current Network:{" "}
              <span className="text-[#4CAF50]">{currentNetwork}</span>
            </h2>
          </div>

          {!walletAddress ? (
            <button
              className="bg-[#F7931A] text-black py-3 rounded-lg hover:bg-[#D77C2B] transition-colors duration-300"
              onClick={() => setIsModalOpen(true)}
              disabled={isLoading}
            >
              {isLoading ? "Connecting..." : "Connect Wallet"}
            </button>
          ) : (
            <>
              <p
                onClick={disconnectWallet}
                className="text-lg text-[#F7931A] p-4 hover:bg-[#000] !rounded !rounded-lg hover:cursor-pointer"
              >
                Connected:{" "}
                <span className="text-[#4CAF50]">
                  {walletAddress
                    ? `${walletAddress.slice(0, 5)}...${walletAddress.slice(
                        -5
                      )}`
                    : ""}
                </span>
              </p>

              <p
                onClick={disconnectWallet}
                className="cursor-pointer flex p-4 text-red-600 text-lg hover:bg-red-800 hover:text-white rounded rounded-lg"
              >
                Disconnect
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="red"
                >
                  <path d="M10 16l-1.41-1.41L12.17 12l-3.58-3.59L10 7l5 5-5 5z" />
                  <path d="M19 21H9v-2h10V5H9V3h10c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2z" />
                </svg>
              </p>
            </>
          )}
        </div>
      </nav>

      <div className="flex flex-col items-center justify-center min-h-[calc(100vh-64px)] gap-10 p-10">
        {/* Animated Header */}
        <p className="text-lg text-center text-[#B58D5C]">
          Securely inscribe your files onto the Bitcoin blockchain.
        </p>

        {/* Modal for Wallet Selection */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-[#1C1E21] p-6 rounded-lg w-full max-w-lg">
              <h2 className="text-2xl font-semibold text-[#F7931A] mb-4">
                Select a Wallet
              </h2>
              <div className="grid grid-cols-2 gap-4">
                {SUPPORTED_WALLETS.map((wallet) => (
                  <button
                    key={wallet}
                    onClick={async () => {
                      setSelectedWallet(wallet);
                      try {
                        await handleConnectWallet(wallet);
                        setIsModalOpen(false); // Close the modal on successful connection
                      } catch (error) {
                        toast.error(
                          `Failed to connect to ${wallet}: ${error.message}`
                        );
                      }
                    }}
                    className="flex flex-col items-center justify-center bg-[#0F1011] text-[#F7931A] p-4 rounded-lg hover:bg-black transition-all duration-300"
                  >
                    <img
                      src={`/assets/images/${wallet}.svg`} // Replace with actual paths to wallet icons
                      alt={`${wallet} logo`}
                      className="h-12 w-12 mb-2"
                    />
                    <span>
                      {wallet.charAt(0).toUpperCase() + wallet.slice(1)}
                    </span>
                  </button>
                ))}
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="mt-6 bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition-colors duration-300 w-full"
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* File Upload Section */}
        <div className="bg-[#1C1E21] p-6 rounded-lg w-full max-w-lg border border-[#F7931A]/30">
          <h2 className="text-2xl font-semibold text-[#F7931A] mb-4">
            Upload Your File
          </h2>
          <div className="text-sm text-[#B58D5C] mb-4">
            Supported file types:{" "}
            <span className="text-[#F7931A]">
              HTML, CSS, Plain Text, JavaScript, JSON, XML, PDF, ZIP, Images,
              Videos, and Audio
            </span>
            .
          </div>
          <label
            htmlFor="file-upload"
            className="w-full flex items-center justify-center px-4 py-6 bg-[#0F1011] text-[#F7931A] border border-[#F7931A] rounded-lg cursor-pointer hover:bg-[#1C1E21] transition-all duration-200"
          >
            <span>
              {selectedFile ? selectedFile.name : "Click to select a file"}
            </span>
            <input
              id="file-upload"
              type="file"
              multiple
              accept={SUPPORTED_MIME_TYPES.join(",")}
              onChange={handleFileChange}
              className="hidden"
            />
          </label>

          <div className="mt-4">
            {selectedFiles.length > 0 ? (
              <ul className="text-sm text-[#B58D5C]">
                {selectedFiles.map((file, index) => (
                  <li key={index} className="mb-2">
                    {file.name} ({file.type})
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-[#B58D5C]">No files selected.</p>
            )}
          </div>

          {selectedFileType && (
            <p className="mt-4 text-sm text-[#B58D5C]">
              You are inscribing a file of type:{" "}
              <span className="text-[#F7931A]">{selectedFileType}</span>
            </p>
          )}
          <button
            onClick={handleInscribeFiles}
            disabled={isLoading || selectedFiles.length === 0}
            className="w-full mt-4 bg-[#F7931A] text-black py-3 rounded-lg hover:bg-[#D77C2B] disabled:opacity-50 transition-colors duration-300"
          >
            {isLoading ? "Inscribing Files..." : "Inscribe Files"}
          </button>

{/* fee calculation  */}
          {selectedFiles.length > 0 ? <button
    onClick={calculateEstimatedFee}
    disabled={selectedFiles.length === 0}
    className="w-full bg-black mt-4 text-white py-3 rounded-lg hover:bg-orange-800 disabled:opacity-50 transition-colors duration-300"
  >
    {selectedFiles.length === 0 ? "Select Files First" : "Calculate Fee"}
  </button>: ""}
        </div>



        {/* Transaction ID Section */}
        {txId.length > 0 && (
  <div className="bg-[#1C1E21] p-6 rounded-lg w-full max-w-lg border border-green-500">
    <div className="flex items-center justify-center mb-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-8 w-8 text-green-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5 13l4 4L19 7"
        />
      </svg>
      <h3 className="ml-2 text-xl font-semibold text-green-400">
        Transactions Successful!
      </h3>
    </div>
    <div className="bg-[#0F1011] p-4 rounded-md border border-green-500">
      {txId.map(({ fileName, txId }, index) => (
        <div key={index} className="mb-4">
          <p className="text-sm text-green-300 break-words">
            <span className="font-semibold text-green-400">{fileName}:</span>{" "}
            <a
              href={`https://mempool.space/${
                currentNetwork === "mainnet"
                  ? ""
                  : currentNetwork === "testnet4"
                  ? "testnet4/"
                  : currentNetwork === "testnet3"
                  ? "testnet/"
                  : currentNetwork === "signet"
                  ? "signet/"
                  : ""
              }tx/${txId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#F7931A] hover:underline"
            >
              {txId}
            </a>
          </p>
        </div>
      ))}
    </div>
    <button
      onClick={() => {
        const txDetails = txId
          .map(({ fileName, txId }) => `${fileName}: ${txId}`)
          .join("\n");
        navigator.clipboard.writeText(txDetails);
        toast.success("Transaction IDs copied to clipboard!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }}
      className="w-full mt-4 bg-[#F7931A] text-black py-3 rounded-lg hover:bg-[#D77C2B] transition-colors duration-300"
    >
      Copy All Transaction IDs
    </button>
  </div>
)}

      </div>
    </div>
  );
};

export default InscriptionApp;

// VERSION 2.1
